import { commonMdEnv } from "./env.md.common";

const env: Partial<typeof commonMdEnv> = {
  production: true,
  page: 'moneydays',
  pageCode: 'md',
  meta: {
    siteTitle: 'Money Days 2024',
    ogSiteName: 'Money Days',
    siteDescription: '',
    ogImageAlt: '',
    ogUrl: 'https://moneydays.ro/2024/',
    ogImage: 'https://moneydays.ro/2024/assets/images/moneydays/moneydays-2024-cover-fb.jpg'
  }
};

export const environment = { ...commonMdEnv, ...env };
