import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {GoogleTagManagerService} from "angular-google-tag-manager";
import {environment} from "../environments/environment";
import {RouterLink, RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports: [RouterLink, RouterOutlet]
})
export class AppComponent implements OnInit {

  favicon: any;

  constructor(
    private gtmService: GoogleTagManagerService,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.gtmService.addGtmToDom();
    }
  }

  ngOnInit() {
    this.favicon = this._document.querySelector('#favicon');
    this.favicon.href = environment.favicon;
  }
}
