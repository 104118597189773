import {ApplicationConfig, importProvidersFrom} from "@angular/core";
import {BrowserModule, provideClientHydration} from "@angular/platform-browser";
import {ReactiveFormsModule} from "@angular/forms";
import {BrowserAnimationsModule, provideAnimations} from "@angular/platform-browser/animations";
import {HttpClientModule, provideHttpClient, withFetch, withInterceptorsFromDi} from "@angular/common/http";
import {GoogleTagManagerModule} from "angular-google-tag-manager";
import {environment} from "../environments/environment";
import {CookieService} from "ngx-cookie-service";
import {AppRouting} from "./routing/app-routing.module";

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      ReactiveFormsModule,
      HttpClientModule,
      GoogleTagManagerModule.forRoot({
        id: environment.google_tagmanager_id,
      }),
      AppRouting
    ),
    CookieService,
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideClientHydration()
  ]
};
