import {GTM} from "../app/shared/constants/variables.constants";

export const commonMdEnv = {
  clientCode: 'MD',
  productCode: '',
  local: false,
  production: true,
  valentin: false,
  bogdan: false,
  moneydays: true,
  dan: false,
  hubix: false,
  client: {
    sb: false,
    cb: false,
    ib: false,
    md: true,
  },
  google_tagmanager_id: GTM.MONEY_DAYS,
  tosUrl: {
    policy: 'https://moneydays.ro/eveniment/politica-de-confidentialitate/',
    cookies: 'https://moneydays.ro/eveniment/politica-privind-fisierele-cookies/',
    protectiaDatelor: 'https://moneydays.ro/eveniment/politica-de-confidentialitate/',
  },
  favicon: 'assets/images/favicon-md.ico',
  page: '',
  pageCode: '',
  meta: {
    siteTitle: 'Money Days',
    ogSiteName: 'Money Days',
    ogUrl: '',
    ogImage: '',
    siteDescription: '',
    ogImageAlt: ''
  },
  landingPage: {
    cursIINivel1: '',
    cursIINivel2: '',
    cursIINivel3: '',
    imobiliare: '',
    imobiliareNivel2: '',
    incomeAccelerator: '',
    cpip: '',
    sbP12: '',
    moneySmart: '',
    moneydays: 'https://moneydays.ro/2022/',
    deNeinvins: '',
    primulPortofoliu: '',
    rstCursBursa: '',
  },
  product: {
    cursIINivel2 :{
      silver: '',
      gold: '',
      vip: '',
    },
    cursIINivel1 :{
      silver: '',
      gold: '',
      vip: '',
    },
    cursIINivel3 :{
      silver: '',
      gold: '',
      vip: '',
    },
    imobiliare :{
      silver: '',
      gold: '',
      vip: '',
    },
    imobiliareNivel2 :{
      silver: '',
      gold: '',
      vip: '',
    },
    incomeAccelerator :{
      silver: '',
      gold: '',
      vip: '',
    },
    cpip :{
      silver: '',
      gold: '',
      vip: '',
    },
    sbPachetMentoring :{
      silver: 'https://stiintabanilor.ro/cos/?add-to-cart=25453',
    },
    sbPachetMinicursuri :{
      silver: '',
    },
    cbPachetInitiere :{
      silver: '',
    },
    cbPachetMentoring :{
      silver: '',
    },
    moneySmart :{
      silver: '',
      gold: '',
      vip: '',
    },
    moneydays :{
      silver: 'https://ideisibani.ro/cos/?add-to-cart=7354',
      gold: 'https://ideisibani.ro/cos/?add-to-cart=7355',
      vip: 'https://ideisibani.ro/cos/?add-to-cart=7356',
    },
    deNeinvins :{
      silver: '',
    },
    sbP10 :{
      silver: '',
    },
    sbP12 :{
      silver: 'https://stiintabanilor.ro/cos/?add-to-cart=25449',
    },
    primulPortofoliu :{
      silver: '',
    },
    sbPachetIncepatori :{
      silver: '',
    },
    sbPachetInvestitii :{
      silver: 'https://stiintabanilor.ro/cos/?add-to-cart=25446',
    },
    sbPachetAvansati :{
      silver: '',
    },
    sbPachetToateCursurile :{
      silver: '',
    },
    sbPachetEndlessPerfectSummer :{
      silver: '',
    },
    cbPachetToateCursurile :{
      silver: '',
    },
    cbPachetInvestitii :{
      silver: '',
    },
    cbPachetBursa :{
      silver: '',
    },
    cbPachetImobiliare :{
      silver: '',
    },
    rstCursBursa :{
      silver: '',
    },
  }
};
