export const GTM = {
  STIINTA_BANILOR : "GTM-PKMTLHV",
  CARUTA_CU_BANI  : "GTM-T9NLW69",
  IDEI_SI_BANI    : "",
  MONEY_DAYS      : "GTM-T2HTJTP",
  HUBIX           : "GTM-M9TJXKDC",
};

export const FB_GROUP = {
  TRUE_FIRE : "True Fire",
  IIF        : "Investitorul Independent Financiar",
};
