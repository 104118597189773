import { RouterModule, Routes } from '@angular/router';

let routes: Routes = [
  {
    path: '',
    loadChildren: () => import('../pages/moneydays/moneydays.module').then((m) => m.MoneydaysModule),
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/',
    pathMatch: 'full',
  },
];

export const AppRouting = RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
});
